import React from 'react'
import { Link } from 'gatsby'
import classnames from 'classnames'
import Equalizer from '../components/Equalizer'
import PageHeader from '../components/PageHeader'

import Layout from '../components/layout'
import SEO from '../components/seo'

import InterviewItem from '../components/InterviewItem'

import arrow from '../images/cta-arrow.svg'

class InterviewsPage extends React.PureComponent {
  constructor(props) {
    super(props)

    if (typeof window !== 'undefined') {
      this.media = window.matchMedia('screen and (min-width: 900px)')
    }
  }

  getNodesForEqualizer(nodePrefix) {
    return Object.getOwnPropertyNames(this)
      .filter(property => property.indexOf(nodePrefix) === 0)
      .map(property => this[property])
  }

  render() {
    const { podcasts } = this.props.pageContext
    const { props } = this

    const hasPreviousPage = props.pageContext.currentPage > 1
    const hasNextPage =
      props.pageContext.currentPage < props.pageContext.numPages

    return (
      <Layout>
        <SEO title="Longevity Podcasts" keywords={[`longevity`]} />

        <PageHeader
          titles={['Our Podcasts']}
          descriptions={[
            'We are regularly joined by recognized experts in the field of Longevity who share their insights in the latest trends in our sector.',
            'Feel free to browse through our list of podcast interviews and gain insights from today’s experts as they share their unique perspectives into living longer, healthier, happier lives.',
          ]}
        />

        <div className="container lg:max-w-xl mx-auto mb-24 lg:mb-32">
          <Equalizer
            byRow={true}
            forceGroupItemsCount
            groupItemsCount={2}
            enabled={() => this.media.matches}
            nodes={() => this.getNodesForEqualizer('nameNode')}
          >
            <Equalizer
              byRow={true}
              forceGroupItemsCount
              groupItemsCount={2}
              enabled={() => this.media.matches}
              nodes={() => this.getNodesForEqualizer('authorTitleNode')}
            >
              <Equalizer
                byRow={true}
                forceGroupItemsCount
                groupItemsCount={2}
                enabled={() => this.media.matches}
                nodes={() => this.getNodesForEqualizer('titleNode')}
              >
                <Equalizer
                  byRow={true}
                  forceGroupItemsCount
                  groupItemsCount={2}
                  enabled={() => this.media.matches}
                  nodes={() => this.getNodesForEqualizer('headerNode')}
                >
                  <div className="lg:flex lg:flex-wrap lg:-mx-4">
                    {podcasts.map((podcast, index) => (
                      <div className="lg:w-1/2 lg:px-4" key={index}>
                        <InterviewItem
                          nameRef={node => (this[`nameNode${index}`] = node)}
                          titleRef={node => (this[`titleNode${index}`] = node)}
                          headerRef={node =>
                            (this[`headerNode${index}`] = node)
                          }
                          authorTitleRef={node =>
                            (this[`authorTitleNode${index}`] = node)
                          }
                          avatar={podcast.expert.photo.url}
                          name={podcast.expert.name}
                          authorTitle={podcast.expert.title}
                          title={podcast.title}
                          focusAreaUid={podcast.focusarea._meta.uid}
                          expertTitle={podcast.expert.title}
                          createdAt={podcast._meta.firstPublicationDate}
                          index={index}
                          uid={podcast._meta.uid}
                        />
                      </div>
                    ))}
                  </div>
                </Equalizer>
              </Equalizer>
            </Equalizer>
          </Equalizer>

          {props.pageContext.numPages && (
            <ul
              id="pagination"
              className="list-reset flex items-center font-sans my-16 lg:my-24 flex-wrap justify-start px-4 lg:px-0"
            >
              <li className="w-1/3">
                <Link
                  to={`/podcasts${
                    props.pageContext.currentPage === 2
                      ? ''
                      : `/${props.pageContext.currentPage - 1}`
                  }`}
                  className={classnames(
                    'font-serif text-base text-blue-dianne flex items-center no-underline',
                    {
                      invisible: !hasPreviousPage,
                    }
                  )}
                >
                  <img
                    src={arrow}
                    alt=""
                    className="mr-4 lg:mr-8 h-6 svg-mirror"
                  />
                  {/* <span className="cta-ie11-fix">Previous</span>{' '} */}
                </Link>
              </li>
              <li style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                <p className="font-serif text-blue-dianne">
                  Page {props.pageContext.currentPage} of{' '}
                  {props.pageContext.numPages}
                </p>
              </li>
              <li className="w-1/3">
                <Link
                  to={`/podcasts/${props.pageContext.currentPage + 1}`}
                  className={classnames(
                    'font-serif text-base text-blue-dianne flex items-center justify-end no-underline',
                    {
                      invisible: !hasNextPage,
                    }
                  )}
                >
                  {/* <span className="cta-ie11-fix">Next</span>{' '} */}
                  <img src={arrow} alt="" className="ml-4 g:ml-8 h-6" />
                </Link>
              </li>
            </ul>
          )}
        </div>
      </Layout>
    )
  }
}

export default InterviewsPage
