import React from 'react'
import { Link } from 'gatsby'
import classnames from 'classnames'
import { Date as PrismicDate } from 'prismic-reactjs'
import moment from 'moment'

export default function Interview({
  nameRef,
  titleRef,
  authorTitleRef,
  name,
  expertTitle,
  date,
  title,
  avatar,
  className,
  index,
  headerRef,
  focusAreaUid,
  createdAt,
  uid,
}) {
  return (
    <div className="mt-24">
      <div className="bg-selago pb-16 lg:px-8 mt-24 pt-8">
        <div className="lg:flex">
          <div className="w-48 lg:w-32 flex-no-shrink mx-auto lg:mx-0">
            <Link
              to={`/podcast/${uid}`}
              className="block no-underline text-blue-dianne"
            >
              <img src={avatar} alt="" className="block" />
            </Link>
          </div>
          <div className="px-8 lg:pl-8 lg:pr-0 pt-8">
            <p
              className="font-serif px-8 lg:px-0 text-lg font-normal"
              ref={nameRef}
            >
              <Link
                to={`/podcast/${uid}`}
                className="no-underline text-blue-dianne"
              >
                {name || 'Manuel Serrano'}
              </Link>
            </p>
            <p className="font-sans px-8 lg:px-0 mt-4 lg:mt-4" ref={titleRef}>
              <Link
                to={`/podcast/${uid}`}
                className="no-underline text-blue-dianne"
              >
                {expertTitle}
              </Link>
            </p>
          </div>
        </div>
      </div>
      <div
        className={classnames(
          'bg-white p-8 measure mx-auto lg:mx-8 lg:-mt-8 border-t-4',
          focusAreaUid ? `border-${focusAreaUid}` : undefined,
          {
            'border-age-science': index % 1 === 0 && !focusAreaUid,
            'border-preventive-healthcare': index % 2 === 1 && !focusAreaUid,
            'border-longevity-economy': index % 3 === 2 && !focusAreaUid,
          }
        )}
      >
        <p className="font-sans text-gothic px-8 lg:px-0 mb-4 text-sm">
          {moment(PrismicDate(createdAt)).format('MMMM DD, YYYY')}
        </p>
        <p className="font-serif text-lg2" ref={headerRef}>
          <Link
            to={`/podcast/${uid}`}
            className="no-underline text-blue-dianne"
          >
            {title}
          </Link>
        </p>
        <Link
          to={`/podcast/${uid}`}
          className="block mt-8 font-sans text-gothic text-sm uppercase no-underline"
        >
          Learn more
        </Link>
      </div>
    </div>
  )
}
